import request from '@/utils/request'

// 登录方法
export function loginDo(data) {
  return request({
    url: '/login_do',   
    method: 'post',
    data: data
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/index/user_info',
    method: 'get'
  })
}

// 注册方法
export function registerDo(data) {
  return request({
    url: '/register_do',   
    method: 'post',
    data: data
  })
}


// 获取验证码
export function getCode() {
  return request({
    url: '/get_code',   
    method: 'get'
  })
}
// 注册短信验证码
export function registerSendSms(data) {
  return request({
    url: '/register_send_sms',   
    method: 'post',
    data: data
  })
}


