
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import User from '../views/User.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component:() => import('../views/map.vue'),
        meta:{
            title:'太空罗盘'
        }
    },
    {
        // 可视化
        path: '/map',
        component:() => import('../views/map.vue'),
        meta:{
            title:'太空罗盘'
        }
    },
    // {
    //     // 态势
    //     path: '/taishi',
    //     component:() => import('../views/Taishi.vue'),
    //     meta:{
    //         title:'态势感知'
    //     }
    // },
    {
        // 预警计算
        path: '/collisionwarning',
        component:() => import('../views/CollisionWarning.vue'),
        meta:{
            title:'预警计算'
        }
    },
    // {
    //     // 首页
    //     path: '/main',
    //     name: 'Main',
    //     component: () => import('../views/Main.vue'),
    //     children:[// 开始嵌套路由，这下面的所有路由都是Main路由的子路由
    //         {
    //             path:'/login', // 登录页
    //             name:'Login',
    //             component:() => import('../views/Login.vue'),
    //             meta:{
    //                 title:'登录'
    //             }
    //         },
    //         {
    //             path:'/register', // 注册页
    //             name:'Register',
    //             component:() => import('../views/Register.vue'),
    //             meta:{
    //                 title:'注册'
    //             }
    //         },
    //         {
    //             path:'/index', // 首页的路由
    //             name:'Index',
    //             component:() => import('../views/Index.vue')
    //         },
    //     ]
    // },
    {
        path:'/*', // 注意，这里不是嵌套路由了，这是为了设置404页面，一定要放在最后面，这样当服务器找不到页面的时候就会全部跳转到404
        name:'404',
        component: () => import('../error/404.vue')
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router