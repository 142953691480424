import Vue from 'vue'
import App from './App.vue'
import router from './router' // 导入路由 
import store from './store';
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Pagination from "@/components/Pagination";
import dataV from '@jiaminghi/data-view'
import * as math from 'mathjs'

// 全局挂载math
Vue.prototype.$math = math

Vue.config.productionTip = false

Vue.use(dataV)

//全局注册分页组件
Vue.component('Pagination', Pagination)

// 使用element-ui
Vue.use(ElementUI)

Vue.prototype.msgSuccess = function (msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: "success"
  });
}

Vue.prototype.msgError = function (msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: "error"
  });
}

router.beforeEach((to, form, next) => {
  window.document.title = to.meta.title == undefined ? '太空罗盘' : to.meta.title
  next()
})

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
