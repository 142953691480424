import Vue from 'vue'
import Vuex from 'vuex'
import storage from '@/utils/storage'

Vue.use(Vuex);
// 用Vuex.Store对象用来记录token
const store = new Vuex.Store({

  state: {
    // 存储token
    token: "",
    userName: "", // 可选
    fangzhentime: undefined,
    shouldAnimate: true,
    objectOptions: [],
    objectCurrent: '',
    windowHeight: '',
    windowWidth: '',
  },

  getters: {
    getWindowWidth(state) {
      return state.windowWidth
    },
    getWindowHeight(state) {
      return state.windowHeight
    },
    getToken(state) {
      return state.token || storage.get("token") || "";
    },
    getUserName(state) {
      return state.userName || storage.get("userName") || "";
    }
  },

  mutations: {
    setWindowWidth(state, width) {
      state.windowWidth = width
    },
    setWindowHeight(state, height) {
      state.windowHeight = height
    },
    // 修改token，并将token存入localStorage
    setToken(state, token) {
      state.token = token;
      storage.set('token', token);
      console.log('store、localstorage保存token成功！');
    },
    delToken(state) {
      state.token = "";
      storage.remove("token");
      state.userName = "";
      storage.remove("userName");
    },
    // 可选
    setUserInfo(state, userName) {
      storage.set('userName', userName);
      state.userName = userName;
    },
    setSimTime(state, newval) {
      state.fangzhentime = newval;
    },
    setShouldAnimate(state, newval) {
      state.shouldAnimate = newval;
    },
    setObjectOptions(state, newval) {
      state.objectOptions = newval;
    },
    setObjectCurrent(state, newval) {
      state.objectCurrent = newval;
    },

  },

  actions: {

  },
});

export default store;
